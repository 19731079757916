<template>
  <div id="app">
    <!-- 视频组件 -->
    <dualVideoCom
      ref="dualVideoCom"
      :mainStreamUrl="mainStreamUrl"
      :subStreamUrl="subStreamUrl"
      :examOperationTest="examOperationTest"
      :openAI="openAI"
      :showFace="showFace"
      @renderPointFrame="renderPointFrame"
      @changeShowFace="(val)=>{
        showFace = val
      }"
    ></dualVideoCom>

    <el-row :gutter="5" id="markingVideoFormalTable">
      <el-col :span="12">
        <!-- ,'backgroundColor':'#dfe6ec' -->
        <el-table
          v-loading="loading"
          stripe
          border
          ref="markingVideoTable"
          :data="leftOperationQuestionList"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column
            prop="index"
            label="序号"
            show-overflow-tooltip
            :width="56"
          ></el-table-column>
          <el-table-column label="评分项" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.operationQuestionName }}
              <el-tooltip
                v-if="scope.row.markSuggest && scope.row.markSuggest.remark"
                effect="dark"
                :content="scope.row.markSuggest.remark"
                placement="bottom"
              >
                <i
                  class="el-icon-warning-outline"
                  style="float: right; line-height: 23px; font-size: 18px"
                ></i>
              </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column
            prop="score"
            label="分值"
            show-overflow-tooltip
            :width="61"
          ></el-table-column>
          <el-table-column
            prop="aiScore"
            label="AI初评"
            :width="80"
            align="center"
            v-if="openAI"
          >
            <template slot-scope="scope">{{
              scope.row.aiSupport ? scope.row.aiScore : 0
            }}</template>
          </el-table-column>
          <el-table-column
            align="center"
            v-if="!openAI"
            label="操作"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-button
                v-throttle
                size="small"
                type="primary"
                @click="fullMarking(scope.row)"
                >满分</el-button
              >
              <el-button
                v-throttle
                size="small"
                type="danger"
                @click="zeroMarking(scope.row)"
                >零分</el-button
              >
              <el-input
                v-model="scope.row.inputScore"
                style="width: 60px; margin-left: 10px"
                @input="scoreInput(scope.row, scope.row.inputScore)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            v-if="openAI"
            :width="100"
            label="人工复核"
            align="center"
          >
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.inputScore"
                style="width: 60px; margin: 0 auto"
                @input="scoreInput(scope.row, scope.row.inputScore)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="left"
            v-if="openAI"
            label="评分依据"
            show-overflow-tooltip
            :width="120"
            v-once
          >
            <template slot-scope="scope">
              <div
                v-if="
                  renderPointFrame(scope.row.aiResultOperationQuestionItemDTO)
                    .length > 0
                "
                class="pointFrameImg"
                style="height: 54px"
                @click="
                  jumpPointFrameListVideo(
                    renderPointFrame(scope.row.aiResultOperationQuestionItemDTO)
                  )
                "
              >
                <img
                  :src="
                    renderPointFrameImg(
                      renderPointFrame(
                        scope.row.aiResultOperationQuestionItemDTO
                      )
                    )
                  "
                />
              </div>
              <div v-else style="height: 54px"></div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>

      <el-col :span="12">
        <el-table
          v-loading="loading"
          stripe
          border
          ref="markingVideoTable"
          :data="rightOperationQuestionList"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column
            prop="index"
            label="序号"
            show-overflow-tooltip
            :width="56"
          ></el-table-column>
          <el-table-column label="评分项" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.operationQuestionName }}
              <el-tooltip
                v-if="scope.row.markSuggest && scope.row.markSuggest.remark"
                effect="dark"
                :content="scope.row.markSuggest.remark"
                placement="bottom"
              >
                <i
                  class="el-icon-warning-outline"
                  style="float: right; line-height: 23px; font-size: 18px"
                ></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="score"
            label="分值"
            show-overflow-tooltip
            :width="61"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="aiScore"
            label="AI初评"
            :width="80"
            v-if="openAI"
          >
            <template slot-scope="scope">{{
              scope.row.aiSupport ? scope.row.aiScore : 0
            }}</template>
          </el-table-column>
          <el-table-column
            align="center"
            v-if="!openAI"
            label="操作"
            :min-width="60"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-button
                v-throttle
                size="small"
                type="primary"
                @click="fullMarking(scope.row)"
                >满分</el-button
              >
              <el-button
                v-throttle
                size="small"
                type="danger"
                @click="zeroMarking(scope.row)"
                >零分</el-button
              >
              <el-input
                v-model="scope.row.inputScore"
                style="width: 60px; margin-left: 10px"
                @input="scoreInput(scope.row, scope.row.inputScore)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            v-if="openAI"
            :width="100"
            label="人工复核"
          >
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.inputScore"
                style="width: 60px"
                @input="scoreInput(scope.row, scope.row.inputScore)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="left"
            v-if="openAI"
            label="评分依据"
            show-overflow-tooltip
            :width="120"
            v-once
          >
            <template slot-scope="scope">
              <div
                v-if="
                  renderPointFrame(scope.row.aiResultOperationQuestionItemDTO)
                    .length > 0
                "
                class="pointFrameImg"
                style="height: 54px"
                @click="
                  jumpPointFrameListVideo(
                    renderPointFrame(scope.row.aiResultOperationQuestionItemDTO)
                  )
                "
              >
                <img
                  :src="
                    renderPointFrameImg(
                      renderPointFrame(
                        scope.row.aiResultOperationQuestionItemDTO
                      )
                    )
                  "
                />
              </div>
              <div v-else style="height: 54px"></div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <el-row class="marking-video-footer">
      <span>
        考生得分：
        <span>{{ totalScore }}</span> 分
      </span>
      <el-button v-throttle size="medium" type="primary" @click="submitExit"
        >提交并退出</el-button
      >
      <el-button v-throttle size="medium" type="primary" @click="submitContinue"
        >提交并继续</el-button
      >
      <!-- <el-button v-throttle  size="medium" type="primary" @click="getNextTest">下一份</el-button> -->
      <el-button v-throttle size="medium" type="primary" @click="exit"
        >仅退出</el-button
      >
    </el-row>
  </div>
</template>
<script>
import dualVideoCom from '@/components/dualVideoCom.vue'
import {
  getOneTestApi,
  getOneReviewTestApi,
  updateScoreMarkApi,
} from '@/api/marking/markingVideoFormal.js'
import { getExamineeAiMarkingApi } from '@/api/marking/ai.js'
import { isEmpty, durationToTimeSting, throttle } from '@/utils/util'
export default {
  name: 'markingVideoFormal',
  components: {
    dualVideoCom,
  },
  data() {
    return {
      mainStreamUrl: '',
      subStreamUrl: '',
      loading: false,
      submitForm: {
        remark: '',
      },
      examId: '',
      operationTestId: '',
      examOperationTest: {},
      aiMarkResult: {},
      frameList: [],
      operationQuestionList: [],
      leftOperationQuestionList: [],
      rightOperationQuestionList: [],
      totalScore: 0,
      cellSelectedStyle:
        'border:1px solid rgba(255 143 143);text-align:center;',
      cellNoneStyle: 'border:0;text-align:center;',

      openAI: false,
      showFace: false
    }
  },

  mounted() {
    this.examId = this.$route.query.examId
    this.operationTestId = this.$route.query.operationTestId
    console.log(
      `examId:${this.examId}, operationTestId:${this.operationTestId}`
    )
    // this.initPlayer(this.mainStreamUrl, this.subStreamUrl)
    this.init()
  },
  beforeDestroy() {
    // this.destroyPlayer()
  },
  methods: {
    init() {
      if (isEmpty(this.examId) || isEmpty(this.operationTestId)) {
        return
      }
      //获取一份试卷
      this.getOneTest()
    },
    getOneTest() {
      this.loading = true
      let data = {
        channel: 0,
      }
      getOneTestApi(this.examId, this.operationTestId, data)
        .then(async (res) => {
          if (res.success) {
            let dataList = null
            if (res.data.aiSupport) {
              this.openAI = true

              // dataList = await this.getAiMark(this.examId, res.data.examineeId)
            }
            this.renderData(res.data, dataList)
            this.$refs.dualVideoCom.getExamineeVideo(res.data.examineeId)
          } else {
            let msg = ''
            switch (res.code) {
              case '506':
                msg = '暂无待阅卷的考生'
                break
              case '801':
                msg = res.msg
                break
              case '805':
                this.getOneReviewTest()
                break
              case '809':
                msg = res.msg
                break
              case '821':
                msg = res.msg
                break
              case '831':
                msg = res.msg
                break
              case '814':
                msg = res.msg
                break
              case '817':
                msg = res.msg
                break
            }
            if (
              res.code == '506' ||
              res.code == '821' ||
              res.code == '831' ||
              res.code == '814'
            ) {
              this.msgSuccess(msg)
              this.$router.push({
                path: `/marking/markingDetails`,
              })
            } else {
              if (res.code != 805) {
                this.msgWarning(msg)
              }
            }
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },

    getOneReviewTest() {
      let json = {
        channel: 0,
      }
      getOneReviewTestApi(this.examId, json).then((res) => {
        if (res.success) {
          this.renderData(res.data)
          // this.getExamineeVideo(res.data.examineeId)
          // 调组件
          this.$refs.dualVideoCom.getExamineeVideo(res.data.examineeId)
        } else {
          if (res.code != 500) {
            let msg = ''
            switch (res.code) {
              case '814':
                msg = res.msg
                break
            }
            if (res.code == '814') {
              this.msgSuccess(msg)
              this.$router.push({
                path: `/marking/markingDetails`,
              })
            } else {
              this.msgWarning(res.msg)
            }
          }
        }
      })
    },
    getExamineeAiMarking() {
      getExamineeAiMarkingApi(
        this.examOperationTest.examId,
        this.examOperationTest.examineeId
      )
        .then((res) => {
          this.loading = false
          if (res.success) {
            this.aiMarkResult = {}
            this.aiMarkResult = res.data
            this.$refs.dualVideoCom.aiMarkResult = this.aiMarkResult
            this.totalScore = 0
            // 获取AI初评的分数
            if (
              this.aiMarkResult.aiResultOperationQuestionItemDTOList &&
              this.aiMarkResult.aiResultOperationQuestionItemDTOList.length > 0
            ) {
              for (
                let i = 0;
                i < this.examOperationTest.operationQuestions.length;
                i++
              ) {
                let optionItem = this.examOperationTest.operationQuestions[i]
                let item =
                  this.aiMarkResult.aiResultOperationQuestionItemDTOList[i]
                optionItem.aiSupport = true
                this.$set(
                  optionItem,
                  'aiOperationItemName',
                  item.aiOperationItemName
                )
                this.$set(optionItem, 'inputScore', item.aiScore)
                this.$set(optionItem, 'aiScore', item.aiScore)
                if (item.aiScore) {
                  this.totalScore += item.aiScore
                }
                optionItem.aiResultOperationQuestionItemDTO = item
                let currentFrameList = this.renderPointFrame(item)
                if (currentFrameList.length > 0) {
                  this.frameList = this.frameList.concat(currentFrameList)
                  this.$refs.dualVideoCom.frameList = this.frameList
                }
              }
              this.frameList.sort(this.frameListSort)
              this.$refs.dualVideoCom.frameList = this.frameList
              let nextFrameStart = 0
              for (
                let i = this.examOperationTest.operationQuestions.length - 1;
                i >= 0;
                i--
              ) {
                let operationQuestion =
                  this.examOperationTest.operationQuestions[i]
                let currentFrameList = this.renderPointFrame(
                  operationQuestion.aiResultOperationQuestionItemDTO
                )
                if (currentFrameList.length > 0) {
                  currentFrameList.sort(this.frameListSort)
                  let firstFrame = currentFrameList[0]
                  if (firstFrame.frameTime) {
                    this.$set(
                      operationQuestion,
                      'frameStartTime',
                      firstFrame.frameTime
                    )
                    this.$set(operationQuestion, 'frameEndTime', nextFrameStart)
                    this.$set(operationQuestion, 'frameFirst', firstFrame)
                    nextFrameStart = operationQuestion.frameStartTime
                  }
                }
              }
            }
          } else {
            if (res.code != '500') {
              this.msgWarning('未获取到考生的AI评分成绩')
            } else {
              this.msgError('获取成绩明细信息失败')
            }
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = true
        })
    },
    renderData(data, aiData = null) {
      this.examOperationTest = {}
      this.examOperationTest = data
      // if (!!aiData) {
      //   this.examOperationTest.markTeacherRecords = []
      //   this.examOperationTest.markTeacherRecords.push(aiData)
      // }
      if (this.examOperationTest.examName.length > 25) {
        this.examOperationTest.aliasExamName =
          this.examOperationTest.examName.substring(0, 25) + '...'
      } else {
        this.examOperationTest.aliasExamName = this.examOperationTest.examName
      }
      this.examOperationTest.examNameLen =
        this.examOperationTest.examName.length
      this.mainStreamUrl = this.$imageAddress(
        this.examOperationTest.topCameraStream0
      )
      if (!isEmpty(this.examOperationTest.frontCameraStream0)) {
        this.subStreamUrl = this.$imageAddress(
          this.examOperationTest.frontCameraStream0
        )
      } else if (!isEmpty(this.examOperationTest.sideCameraStream0)) {
        this.subStreamUrl = this.$imageAddress(
          this.examOperationTest.sideCameraStream0
        )
      } else {
        this.subStreamUrl = this.$imageAddress(
          this.examOperationTest.frontCameraStream0
        )
      }
      this.$nextTick(() => {
        // this.startPlayer(this.mainStreamUrl, this.subStreamUrl)
        this.$refs.dualVideoCom.initPlayer(
          this.mainStreamUrl,
          this.subStreamUrl
        )
        // // 调组件
        // this.$refs.dualVideoCom.startPlayer(
        //   this.mainStreamUrl,
        //   this.subStreamUrl
        // )
      })
      this.totalScore = 0
      this.operationQuestionList = []
      this.leftOperationQuestionList = []
      this.rightOperationQuestionList = []
      let listSize = this.examOperationTest.operationQuestions.length
      let splitPoint = Math.ceil(listSize / 2)
      for (let i = 0; i < listSize; i++) {
        let item = this.examOperationTest.operationQuestions[i]
        item.aiSupport = false
        item.aiScore = 0
        item.aiResultOperationQuestionItemDTO = []
      }
      this.examOperationTest.operationQuestions.forEach((item, index) => {
        item.index = index + 1
        this.$set(item, 'inputScore', item.score)
        this.operationQuestionList.push(item)
        this.totalScore += item.score
        if (index < splitPoint) {
          this.leftOperationQuestionList.push(item)
        } else {
          this.rightOperationQuestionList.push(item)
        }
      })
      if (this.openAI) {
        this.getExamineeAiMarking()
      } else {
        this.loading = false
      }
    },

    fullMarking(row) {
      this.$set(row, 'inputScore', row.score)
      this.computeScore(row)
    },
    zeroMarking(row) {
      this.$set(row, 'inputScore', 0)
      this.computeScore(row)
    },
    scoreInput(row, val) {
      if (isEmpty(val)) {
        this.$set(row, 'inputScore', '')
        this.computeScore()
        return
      }
      val = val.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')
      if (val > row.score) {
        val = row.score
      }
      this.$set(row, 'inputScore', val)
      this.computeScore()
    },
    computeScore() {
      let totalScore = 0
      this.operationQuestionList.forEach((item) => {
        if (isEmpty(item)) {
          return
        }
        if (isEmpty(item.inputScore)) {
          return
        }
        totalScore += Number(item.inputScore)
      })
      this.totalScore = totalScore.toFixed(1)
    },
    checkScore(txt) {
      let queryParam = this.getScoreMarkParam()
      try {
        queryParam.operationQuestions.forEach((item, index) => {
          if (isEmpty(item.score) || isNaN(item.score)) {
            throw new Error(`第${index + 1}行分数不能为空`)
          }
        })
        switch (txt) {
          case 'exit':
            this.updateScoreMark()
            break
          case 'continue':
            this.updateScoreMarkContinue()
            break
        }
      } catch (e) {
        this.msgWarning(e.message)
      }
    },
    //提交并退出
    submitExit() {
      this.checkScore('exit')
    },
    updateScoreMark() {
      let queryParam = this.getScoreMarkParam()
      if (isEmpty(queryParam)) {
        return
      }
      updateScoreMarkApi(JSON.stringify(queryParam))
        .then((res) => {
          if (res.success) {
            if (res.data) {
              this.msgSuccess('提交成功')
              this.showFace = false
              this.$router.push({
                path: `/marking/markingDetails`,
              })
            }
          } else {
            this.msgError(res.msg)
          }
        })
        .catch(() => {})
    },
    //提交并继续
    submitContinue: throttle(function () {
      this.checkScore('continue')
    }, 3000),
    updateScoreMarkContinue() {
      let queryParam = this.getScoreMarkParam()
      if (isEmpty(queryParam)) {
        return
      }
      this.$refs.dualVideoCom.shutDownIsPicture()
      updateScoreMarkApi(JSON.stringify(queryParam))
        .then((res) => {
          if (res.success) {
            if (res.data) {
              this.msgSuccess('提交成功')
              this.showFace = false
              this.getOneTest()
            }
          } else {
            this.msgError(res.msg)
          }
        })
        .catch(() => {})
    },
    getScoreMarkParam() {
      if (this.operationQuestionList.length <= 0) {
        this.msgError('暂无数据')
        return
      }
      let operationQuestions = []
      this.operationQuestionList.forEach((item) => {
        let operationQuestion = {
          operationQuestionId: 0,
          operationQuestionName: '',
          score: '',
          sortNum: 0,
        }
        operationQuestion.operationQuestionId = item.operationQuestionId
        operationQuestion.operationQuestionName = item.operationQuestionName
        if (!isEmpty(item.inputScore)) {
          operationQuestion.score = Number(item.inputScore)
        }
        operationQuestion.sortNum = item.sortNum
        operationQuestions.push(operationQuestion)
      })
      let queryParam = {
        channel: 0,
        examId: this.examOperationTest.examId,
        examineeId: this.examOperationTest.examineeId,
        operationQuestions: operationQuestions,
        operationTestId: this.examOperationTest.operationTestId,
        review: this.examOperationTest.review,
        scoreType: this.examOperationTest.scoreType,
      }
      return queryParam
    },
    //仅退出
    exit() {
      this.globalMessageBox(
        `退出后不保留评卷记录，
      确定退出吗？`,
        '提示',
        '确定',
        '取消',
        'warning'
      )
        .then(() => {
          this.$router.push({ path: `/marking/markingDetails` })
        })
        .catch(() => {})
    },
    jumpPointFrameListVideo(aiResultOperationQuestionItemDTOList) {
      if (aiResultOperationQuestionItemDTOList.length > 0) {
        this.$refs.dualVideoCom.currentDuration = Math.floor(
          aiResultOperationQuestionItemDTOList[0].frameTime / 1000
        )
        this.$refs.dualVideoCom.customSwitchProgress()
      }
    },
    renderPointFrame(aiResultOperationQuestionItemDTO) {
      let list = aiResultOperationQuestionItemDTO.aiResultItemPointDTOList
      if (list && list.length > 0) {
        for (let index = 0; index < list.length; index++) {
          let eventList = list[index]?.aiResultItemPointEventDTOList
          let aiEventList = eventList?.[0]?.aiResultItemPointEventAiEventDTOList
          let frameList =
            aiEventList?.[0]?.aiResultItemPointEventAiEventFrameDTOList
          if (frameList && frameList.length > 0) {
            frameList.forEach((item) => {
              item.displayContent = list[0].aiPointContent
            })
            return frameList
          }
        }
      }
      return []
    },
    renderPointFrameImg(aiResultItemPointEventAiEventFrameDTOList) {
      if (aiResultItemPointEventAiEventFrameDTOList.length > 0) {
        return this.$imageAddress(
          aiResultItemPointEventAiEventFrameDTOList[0].frameUrl
        )
      } else {
        return ''
      }
    },
    frameListSort(frameFirst, frameSecond) {
      if (frameFirst.frameTime - frameSecond.frameTime < 0) {
        return -1
      } else if (frameFirst.frameTime - frameSecond.frameTime > 0) {
        return 1
      } else {
        return 0
      }
    },
  },
}
</script>
<style lang="scss" scoped>
#app {
  padding: 0 5px;
  margin-top: 2px;
}

.exam-info-badge {
  font: normal bolder 16px 'Source Han Sans CN-Medium, Source Han Sans CN';
  color: #fff;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.marking-video-footer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.marking-video-footer > span {
  color: #303133;
  font-size: 30px;
  font-weight: bold;
  margin-right: 20px;
}

.marking-video-footer > span > span {
  color: #409EFF;
}

::v-deep .el-message-box > .el-message-box__btns > {
  text-align: center;
}

::v-deep .el-input__inner {
  height: 32px;
  text-align: center;
}

#markingVideoFormalTable ::v-deep .el-table .el-table__cell {
  padding: 6px 0;
}

.pointFrameImg {
  width: 100%;
  height: 100%;
  border: 1px solid #cbd8f5;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
