import { request } from '@/utils/request.js'

//获取一份试卷
export function getOneTestApi (examId, operationTestId, data) {
  return request({
    url: `/exam/score/${examId}/${operationTestId}/get`,
    method: 'post',
    data: data
  })
}

//获取一份回评审查试卷(阅卷最后一轮次达不到容量时调用该接口)
export function getOneReviewTestApi (examId, data) {
  return request({
    url: `/exam/score/${examId}/review/get`,
    method: 'post',
    data: data
  })
}

//试阅卷实操评分
export function updateScoreMarkApi (data) {
  return request({
    url: `/exam/score/mark/update`,
    method: 'post',
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    data: data
  })
}



